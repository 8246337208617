.p-checkbox {
  @apply
    tw-scale-90;

  .p-checkbox-box {
    @apply
      tw-border-solid
      tw-border-border-primary
      tw-rounded-border-radius-5px
      tw-border-border-1.5;

    .p-icon {
      @apply
        tw-h-10-px
        tw-w-10-px;
    }

    &.p-highlight {
      @apply
        tw-bg-bg-secondary
        tw-border-border-primary;
    }
  }

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &.p-highlight {
        @apply
          hover:tw-border-border-primary
          hover:tw-bg-bg-secondary;
      }

      &.p-focus {
        @apply
          tw-shadow-none
          tw-border-border-primary
      }
    }
  }
}
