.fdl-multi-select {
  @apply
    tw-border-1-px;

  &.p-multiselect-panel {
    @apply
      tw-min-w-260-px;

    .p-multiselect-items-wrapper {
      @apply
        tw-rounded-border-radius-2xs
        tw-py-spacing-4
        tw-shadow-lg;
    }

    .p-multiselect-item {
      @apply
        tw-min-h-32-px
        tw-h-auto
        tw-break-words
        tw-py-spacing-6
        tw-px-spacing-12
        tw-mx-spacing-0
        tw-text-text-secondary
        tw-leading-1.4
        focus:tw-shadow-none;

      &.p-highlight {
        @apply
          hover:tw-bg-bg-white
          tw-bg-bg-white
          tw-text-text-brand-primary;
      }

      .p-checkbox {
        @apply
          tw-mr-spacing-8;
      }

      &:not(.p-highlight) {
        &:not(.p-disabled) {
          @apply
            hover:tw-bg-bg-tertiary
            hover:tw-text-text-secondary;
        }

        &.p-focus {
          @apply
            tw-bg-transparent;
        }
      }
    }

    .p-multiselect-footer {
      @apply
        tw-mx-spacing-4
        tw-mb-spacing-4
    }
  }

  .p-multiselect-empty-message {
    .loader-container {
      @apply
        tw-flex
        tw-items-center
        tw-justify-center
        tw-py-spacing-10;
  
      .loading-spinner {
        @apply
          tw-animate-loading-spinner
          tw-fill-primary
          tw-h-24-px
          tw-w-24-px
      }
    }
  }

  .p-multiselect-header {
    @apply
      tw-border-none
      tw-p-spacing-0
      tw-flex-col
      tw-items-start;

      .select-all {
        @apply
          tw-border-b-0
          tw-py-spacing-8
          tw-px-spacing-12
          tw-w-full;
      }
      
    .search-container {
      @apply
        tw-flex
        tw-w-full
        tw-h-44-px
        tw-rounded-t-border-radius-2xs
        tw-bg-bg-tertiary;
    }
  
    .search-input {
      @apply
        tw-flex
        tw-h-32-px
        tw-rounded-border-radius-2xs
        tw-border
        tw-border-solid
        tw-border-border-secondary
        tw-m-spacing-6
        tw-w-full
        tw-py-spacing-4
        tw-pl-spacing-40
        tw-pr-spacing-12
        focus:tw-border-border-primary
    }
  
    .icon-search {
      @apply
        tw-absolute
        tw-fill-icon-muted
        tw-top-spacing-14
        tw-left-spacing-24
        tw-w-18-px
        tw-h-18-px
    }

    .search-spinner {
      @apply
        tw-absolute
        tw-top-spacing-14
        tw-right-spacing-16
        tw-w-18-px
        tw-h-18-px
        tw-fill-primary
        tw-animate-loading-spinner
    }
  }

  .p-multiselect-trigger {
    @apply
      tw-w-auto
      tw-ml-spacing-6
      tw-p-spacing-0;
  }

  &.p-multiselect {
    @apply
      tw-flex
      tw-items-center
      tw-px-spacing-12
      tw-py-spacing-4
      tw-rounded-border-radius-2xs
      tw-border-border-secondary;

    &:not(.p-disabled) {
      @apply
        hover:tw-border-border-secondary;

      &.p-focus {
        @apply
          tw-shadow-none
          tw-border-border-primary;
      }
    }

    &.size-small {
      @apply
        tw-h-32-px;
    }

    &.size-medium {
      @apply
        tw-h-40-px;
    }

    &.size-large {
      @apply
        tw-h-48-px;
    }

    .p-multiselect-label {
      @apply
        tw-block
        tw-p-spacing-0
        tw-text-size-14
        tw-text-text-secondary;

      .selected-values {
        @apply
          tw-flex
          tw-items-center
          tw-justify-start
          tw-gap-spacing-4
          tw-p-spacing-0;
      }

      .fdl-multiselect-chip {
        @apply
          tw-flex
          tw-py-spacing-2
          tw-px-spacing-8
          tw-rounded-border-radius-xl
          tw-border
          tw-border-solid
          tw-border-border-secondary
          tw-font-500
          tw-text-text-tertiary
          tw-text-size-12
      }

      &.p-placeholder {
        @apply
          tw-leading-1.4
          tw-text-text-placeholder;
      }
    }

    // Older Quick filters from AngularJS are having placeholder size of 12px, hence to make it consistent with new quick filters, we are adding this style
    // Reference slack thread: https://fylein.slack.com/archives/C05PHCCKF6X/p1704263627147539
    &.list-views-quick-filter {
      .p-placeholder {
        @apply
          tw-text-size-12
      }
    }
  }
}

// When the parent form control is marked as invalid, the same is not propagated to the nested form control
// this is issue with nested ControlValueAccessor - https://github.com/angular/angular/issues/42815
// Adding fyl-project-category-multiselect as well, since its also a wrapper around multi-select
ui-multi-select, 
feature-project-category-multiselect {
  &.ng-touched {
    &.ng-invalid {
      p-multiselect {
        &.ng-touched {
          .p-multiselect {
            @apply 
              tw-border-border-danger;
          }
        }
      }
    }
  }
}